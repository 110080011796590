import React from 'react'

import TemplateController from '../category-page-template/template-controller'
import CompanyView from './company-view'

const CompanyController = ({ data, location, pageContext }) => {
  const lang = pageContext.lang
  const viewProps = TemplateController({
    data,
    location,
    slug: 'company-news',
    lang,
  })

  return <CompanyView {...viewProps} />
}

export default CompanyController
